import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';

export function useMojoFetch(url, method, body?: any, shouldNotRun?: boolean) {
  const [data, setData] = useState([] as any);
  const [error, setError] = useState({ message: '' });
  const [isLoading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    setLoading(true);
    let newUrl = process.env.REACT_APP_API_URL + url;
    const token = await getAccessTokenSilently();
    const options: RequestInit = {
      headers: {
        "Content-Type": "application/json", 
        Authorization: 'Bearer ' + token
      },
      method: method,
      ...(body && { body: JSON.stringify(body) })
    };
    try {
      const response = await fetch(newUrl, options);
      if (response.status === 404) { // dirty hack to account for AWS dirty behavior
        setError({ message: 'not found: 404' });
        setLoading(false);
        return;
      } else if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        setError({ message: `${response.statusText}: ${response.status}` });
        setLoading(false);
        return;
      }
      try {
        setData(await response.json());
      } catch {
        // swallow error if JSON parsing fails
      }
    } catch (error: any) {
      setError({ message: `${error.message}` });
    } finally {
      setLoading(false);
    }
  };

  // Use effect unconditionally, just controlling logic inside it
  useEffect(() => {
    if (!shouldNotRun) { 
      fetchData();
    }
  }, [shouldNotRun]);  // Only run when `shouldRun` changes

  const run = async () => {
    await fetchData();
  };

  const refetch = async () => {
    await fetchData();
  };

  return { data, error, isLoading, refetch, run };
}
