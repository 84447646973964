import { useParams } from "react-router-dom";
import { TabItemEnum } from "./TabItemEnum";
import { useNavigate } from "hooks/useNavigate";

export type TabsProps = {
    currentTab: TabItemEnum;
  }

export default function Tabs({ currentTab }: TabsProps) {
    const { tenantId } = useParams();
    
    const getTabClass = (tab: TabItemEnum) => {
        return tab === currentTab ? 'item-menu-active' : 'item-menu';
    }

    const getTabItemClass = (tab: TabItemEnum) => {
        return tab === currentTab ? 'menu-label-active' : 'menu-label gothamhtf-book-normal-gray-16px';
    }

    const navigate = useNavigate();

    return (
        <div className="submenu-wrapper submenu">
            <div className="submenu-container submenu">
                <div className={getTabClass(TabItemEnum.Contacts)}
                    onClick={() => navigate(`/clients/${tenantId}/contacts`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Contacts)}>
                        CONTACTS
                    </div>
                </div>
                <div className={getTabClass(TabItemEnum.Users)}
                    onClick={() => navigate(`/clients/${tenantId}/users`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Users)}>
                        USERS
                    </div>
                </div>
                <div className={getTabClass(TabItemEnum.Integrations)}
                    onClick={() => navigate(`/clients/${tenantId}/integrations`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Integrations)}>
                        INTEGRATIONS
                    </div>
                </div>
                <div className={getTabClass(TabItemEnum.Vendors)}
                    onClick={() => navigate(`/clients/${tenantId}/vendors`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Vendors)}>
                        PARTNERS
                    </div>
                </div>
                <div className={getTabClass(TabItemEnum.Info)}
                    onClick={() => navigate(`/clients/${tenantId}/info`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Info)}>
                        INFO
                    </div>
                </div>
                <div className={getTabClass(TabItemEnum.Demographics)}
                     onClick={() => navigate(`/clients/${tenantId}/demographics`)}
                >
                    <div className={getTabItemClass(TabItemEnum.Demographics)}>
                        DEMOGRAPHICS
                    </div>
                </div>
            </div>
        </div>
    );
}