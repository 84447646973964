import React, { useState, useEffect, useRef } from "react";
import {
    Flex,
    Tooltip,
    VStack,
    IconButton,
    useColorModeValue
} from "@chakra-ui/react";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdOutlineClose } from "react-icons/md";
import SidenavItems from "./SideNavItems";
import "./sidebar.scss";

export interface SidenavItem {
    to: string;
    icon: any;
    label: string;
}

export interface SidenavProps {
    navItems: SidenavItem[];
}

export function UTSidenav({ navItems }: SidenavProps) {
    const [expanded, setExpanded] = useState(false);
    const sidebarRef = useRef<HTMLDivElement>(null);

    const toggleSidebar = () => {
        setExpanded(!expanded);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const bg = useColorModeValue("black.600", '#282828');

    return (
        <Flex
            id="mojoSidebar"
            className="mojo-sidebar"
            as="aside"
            ref={sidebarRef}
            style={{  
                bottom: "auto",
                width: expanded ? "210px" : "70px",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <VStack className="sidebar-content" bg={bg} as="nav">
                <SidenavItems navItems={navItems} isOpen={expanded} />
                <Tooltip label={expanded ? 'collapse' : 'expand'}>
                    <IconButton className="sidebar-toggle-button"
                        onClick={toggleSidebar}
                        aria-label={expanded ? "Collapse" : "Expand"}
                        icon={expanded ? <MdOutlineClose /> : <RxHamburgerMenu />}
                        style={{
                            left: expanded ? "auto" : "15px",
                            right: expanded ? "0px" : "auto", 
                        }}
                    />
                </Tooltip>
            </VStack>
        </Flex>
    );
}

export default UTSidenav;
