import { Routes, Route } from 'react-router-dom';
import {
    ClientContacts,
    TenantIntegrations,
    ClientUsers,
    TenantVendors,
    ClientsList,
} from './modules/client';

import { ClientInfo } from "modules/client/Info";
import { ClientDetail } from "modules/client/ClientDetail";
import LoginRedirect from "pages/LoginRedirect";
import { Home } from "pages/Home";
import { Reports } from "pages/Reports";
import { Settings } from "pages/Settings";
import { Credentials } from "pages/Credentials"
import { GoogleOAuthCallback } from "pages/GoogleOauth";

import { Empty } from 'screens/Empty';
import { VendorDetail } from 'modules/vendors/VendorDetail';
import { VendorContacts } from 'modules/vendors';
import { VendorServices } from 'modules/vendors';
import { VendorPresentations } from 'modules/vendors';
import { VendorNotes } from 'modules/vendors';
import { VendorLinks } from 'modules/vendors';
import { VendorsList } from 'modules/vendors';

import { UserCreate, UserList } from 'modules/user';
import ProvidersList from 'modules/provider/ProvidersList';
import AgenciesList from 'modules/agency/AgenciesList/AgenciesList';
import GroupsList from 'modules/group/GroupsList/GroupsList';
import { UserProfile } from 'pages/UserProfile';

import AdminPage from "modules/admin/AdminPage";
import BudgetsIntro from "modules/budget/BudgetsIntro";
import GoalsIntro from "modules/goal/GoalsIntro";
import Dashboard from "modules/dashboard/index";
import ClientDemographics from 'modules/demographics';
import LoadingScreen from 'components/Loading/LoadingScreen';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import { useRoleAndPermissions } from 'hooks/useRolesAndPermissions';

const ProtectedRoute: React.FC<{ element: React.ComponentType<any>; requiredRoles?: string[] }> = ({
    element,
    requiredRoles = [],
}) => {

  const { hasRole, getRolesAndPermission, isAuthenticated, isLoading, error } = useRoleAndPermissions()
  const rolesAndPermissions = getRolesAndPermission()

    const userHasRole = hasRole(requiredRoles)

    if (isLoading) {
        return <LoadingScreen isLoading={true} />;
    }

  if (isAuthenticated && error) {
    return <div>Error:</div>;
  }
    if (isAuthenticated && error) {
        return <div>Error: {error.message}</div>;
    }

  if (isAuthenticated && !userHasRole) {
    return <div>User does not have permission to this feature</div>;
  }

  if (isAuthenticated && rolesAndPermissions?.length < 1) {
    return <div>User has no role. Please assign the user a role and try again</div>;
  }

  const AuthenticatedElement = withAuthenticationRequired(element, {
    onRedirecting: () => {
      return <div>Loading...</div>
    },
  });

    return <AuthenticatedElement />;
};


const routeConfig = [
    { path: '/login', element: <ProtectedRoute element={LoginRedirect} /> },
    { path: '/oauth/google/callback', element: <ProtectedRoute element={GoogleOAuthCallback} requiredRoles={['Super Admin', 'Client Admin', 'Group Admin']}/> },
    { path: '/', index: true, element: <ProtectedRoute element={Dashboard} /> },
    { path: '/home', element: <ProtectedRoute element={Home} requiredRoles={['Super Admin']} /> },
    { path: '/reports', element: <ProtectedRoute element={Reports} /> },
    { path: '/admin', element: <ProtectedRoute element={AdminPage} requiredRoles={['Super Admin']} /> },
    { path: '/organizations', element: <ProtectedRoute element={ProvidersList} requiredRoles={['Super Admin']} /> },
    { path: '/agencies', element: <ProtectedRoute element={AgenciesList} requiredRoles={['Super Admin']} /> },
    { path: '/groups', element: <ProtectedRoute element={GroupsList} requiredRoles={['Super Admin']} /> },
    { path: '/clients', element: <ProtectedRoute element={ClientsList} /> },
    { path: '/clients/new', element: <ProtectedRoute element={ClientDetail} requiredRoles={['Super Admin']} /> },
    { path: '/clients/:tenantId', element: <ProtectedRoute element={ClientDetail} /> },
    { path: '/budgets', element: <ProtectedRoute element={BudgetsIntro} /> },
    { path: '/partners', element: <ProtectedRoute element={VendorsList} /> },
    { path: '/partners/new', element: <ProtectedRoute element={VendorDetail} /> },
    { path: '/goals', element: <ProtectedRoute element={GoalsIntro} /> },
    { path: '/settings', element: <ProtectedRoute element={Settings} requiredRoles={['Super Admin']} /> },
    { path: '/credentials', element: <ProtectedRoute element={Credentials} requiredRoles={['Super Admin']} /> },
    { path: '/users', element: <ProtectedRoute element={UserList} requiredRoles={['Super Admin', 'Client Admin', 'Group Admin']} /> },
    { path: '/users/new', element: <ProtectedRoute element={UserCreate} requiredRoles={['Super Admin', 'Client Admin', 'Group Admin']} /> },
    { path: '/users/modify/:userId', element: <ProtectedRoute element={UserCreate} requiredRoles={['Super Admin', 'Client Admin', 'Group Admin']} /> },
    { path: '/userprofile/:userId', element: <ProtectedRoute element={UserProfile} /> },
    { path: '/clients/:tenantId/contacts', element: <ProtectedRoute element={ClientContacts} requiredRoles={['Super Admin', 'Client Admin', 'Group Admin']} /> },
    { path: '/clients/:tenantId/users', element: <ProtectedRoute element={ClientUsers} requiredRoles={['Super Admin', 'Client Admin', 'Group Admin']} /> },
    { path: '/clients/:tenantId/integrations', element: <ProtectedRoute element={TenantIntegrations} requiredRoles={['Super Admin', 'Client Admin', 'Group Admin']} /> },
    { path: '/clients/:tenantId/vendors', element: <ProtectedRoute element={TenantVendors} requiredRoles={['Super Admin', 'Client Admin', 'Group Admin']} /> },
    { path: '/clients/:tenantId/info', element: <ProtectedRoute element={ClientInfo} requiredRoles={['Super Admin', 'Client Admin', 'Group Admin']} /> },
    { path: '/clients/:tenantId/demographics', element: <ProtectedRoute element={ClientDemographics} requiredRoles={['Super Admin', 'Client Admin', 'Group Admin']} /> },
    { path: '/partners/:partnerId/notes', element: <ProtectedRoute element={VendorNotes} /> },
    { path: '/partners/:partnerId/presentations', element: <ProtectedRoute element={VendorPresentations} /> },
    { path: '/partners/:partnerId/services', element: <ProtectedRoute element={VendorServices} /> },
    { path: '/partners/:partnerId/links', element: <ProtectedRoute element={VendorLinks} /> },
    { path: '/partners/:partnerId/contacts', element: <ProtectedRoute element={VendorContacts} /> },
];

export const MojoRouter = () => {
    return (
        <Routes>
            {routeConfig.map((route, idx) => {
                return (<Route key={`route-${idx}`} path={route?.path} element={route?.element} />)
            })}
            <Route path="*" element={<Empty />} />
        </Routes>
    );
};

export default MojoRouter;
