import { useEffect, useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from "jwt-decode";
import { useParams } from 'react-router-dom';


interface Role {
  entity: string;
  entity_id: string;
  permissions: string[];
  role: string;
}

export const useRoleAndPermissions = () => {
  const { getAccessTokenSilently, user, isAuthenticated, isLoading, error } = useAuth0();
  const [roles, setRoles] = useState<Role[]>([]);
  const [loadingToken, setLoadingToken] = useState(true);
  const [auth0error, setError] = useState("" as string)

  const params = useParams();
  const rolesKey = 'https://mojoplatform.com/roles'

  const permissions = {
    'Super Admin': ['*'],
    'Organization Admin': ['delete: client', 'update: client'],
    'Group Admin': [],
    'Group User': [],
    'Agency User': [],
    'Agency Admin': [],
    'Client Admin': [],
    'Client User': []
  }


  useEffect(() => {
    const fetchTokenAndRoles = async () => {
      try {
        // Fetch the access token
        const token = await getAccessTokenSilently();
        const decodedToken = jwtDecode(token)
        const roles = decodedToken[rolesKey]

        const superAdmin = roles?.System?.map((role: any) => {
          return { role: role['name'], permissions: permissions[role?.name], entity: role['entity'], entity_id: role['entity_id'] }
        }).flat() || []

        const orgRoles = roles?.Organizations?.map((role: any) => {
          return { role: role['name'], permissions: permissions[role?.name], entity: role['entity'], entity_id: role['entity_id'] }
        }).flat() || []


        const clientRoles = roles?.Clients?.map((role: any) => {
          return { role: role['name'], permissions: permissions[role?.name], entity: role['entity'], entity_id: role['entity_id'] }
        }).flat() || []

        const fullRoles = [...superAdmin, ...orgRoles, ...clientRoles]
        setRoles(fullRoles)

      } catch (err: any) {
        console.error('Error fetching token:', err);

        setError(err?.message as string)
      }
    };

    if (isAuthenticated) {
      fetchTokenAndRoles();
    }

  }, [isAuthenticated, getAccessTokenSilently, user])


  const hasRole = (requiredRoles: string[]) => {
    const { tenantId } = params

    const isSuperAdmin = roles.some((r: Role) =>
      r.role === 'Super Admin' && r.entity === 'Mojo Platform'
    );

    if (isSuperAdmin) {
      console.log('User is Super Admin - granting access');
      return true; // Super Admin always has access
    }

    if (!requiredRoles?.length) {
      console.log('No required roles specified');
      return true;
    }

    const filteredRoles = tenantId
      ? roles.filter((r: Role) => r.entity_id === tenantId)
      : roles;

    // Check if every required role is present in filtered roles
    return requiredRoles.every((role) => {
      console.log('Checking required role:', role);
      for (const r of filteredRoles) {
        console.log('Current role:', r);
        if (r.role === role) { // Match role name
          return true; // Found the required role, move to next
        }
      }
      return false; // Required role not found
    });

  }

  const getRolesAndPermission = () => {
    return roles
  }

  return { isAuthenticated, isLoading, error, hasRole, getRolesAndPermission }

}