import { Box, chakra, VStack } from "@chakra-ui/react";
import { useMemo } from "react";

// Create a Chakra-enhanced polygon with CSS prop support
const AnimatedPolygon = chakra("polygon");

const Hexa = (props) => {
  const {
    A,
    B,
    width = 190,
    side = "",
    heading,
    body,
    bodyColor,
    outlineColor,
    animationOutline,
    ...divProps
  } = props;

  const w = width;
  const h = w;

  const { fill, stroke } = useMemo(() => ({
    fill: bodyColor || (body || heading ? "#cecae6" : "white"),
    stroke: outlineColor || "#cecae6",
  }), [body, heading, bodyColor, outlineColor]);

  // Calculate perimeter for dash animation (more precise for hexagon)
  const perimeter = useMemo(() => {
    const sideLength = Math.sqrt((210 - 0) ** 2); // Distance between [50, 0] and [95, 25]
    return sideLength * 6; // 6 sides
  }, []);

  const hoverStyles = {
    transform: "scale(1.02)",
    "& .laser-trace": {
      animation: "laserTrace 1.75s ease-out", // Trigger animation on hover
      opacity: 3, // Show the outline when hovering
    },
  };

  const activeStyles = {
    stroke: outlineColor ? outlineColor : "#faf",
    transform: "scale(.5)",
  };

  // Revert points to original positions
  const points = [
    [50, 0],      // Top center
    [95, 25],     // Top right
    [95, 75],     // Bottom right
    [50, 100],    // Bottom center
    [5, 75],      // Bottom left
    [5, 25],      // Top left
  ].map(([x, y]) => `${x},${y}`).join(" ");

  const padding = w * 0.05;
  const fontSizeHeading = Math.max(w * 0.03, 7);
  const fontSizeBody = Math.max(w * 0.01, 7);

  return (
    <Box
      display="inline-block"
      position="relative"
      w={`${w}px`}
      h={`${h}px`}
      transition="transform 0.3s ease"
      _hover={hoverStyles}
      _active={activeStyles}
      {...divProps}
    >
      <svg
        width={w}
        height={h}
        viewBox="-2 -2 104 104" // Smaller buffer to reduce empty space
        preserveAspectRatio="xMidYMid meet"
      >
        {/* Define drop shadow filter with adjusted bounds */}
        <defs>
          <filter id="dropShadow" x="-30%" y="-30%" width="160%" height="160%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" result="blur" />
            <feOffset in="blur" dx="1.5" dy="1.5" result="offsetBlur" />
            <feComponentTransfer in="offsetBlur" result="offsetBlur">
              <feFuncA type="linear" slope="0.3" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode in="offsetBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>

        {/* Base hexagon shape with shadow */}
        <polygon
          points={points}
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
          filter="url(#dropShadow)" // Apply the shadow filter
        />

        {/* Animated laser trace outline */}
        <AnimatedPolygon
          className="laser-trace"
          points={points}
          fill="none"
          stroke={animationOutline || outlineColor || "#e273ce"} // Laser-like color
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray={`${perimeter / 4} ${perimeter}`} // Dash pattern for laser effect
          strokeDashoffset={perimeter}
          opacity={0} // Hidden by default
          css={{
            "@keyframes laserTrace": {
              "0%": {
                strokeDashoffset: perimeter,
                opacity: 0.3,
              },
              "100%": {
                strokeDashoffset: 0,
                opacity: 1,
              },
            },
          }}
        />

        {/* Adjusted foreignObject to fit within viewBox */}
        <foreignObject x="15" y="15" width="70" height="70">
          <Box
            w="100%"
            h="100%"
            overflow="hidden"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <VStack
              spacing={1}
              p={`${padding}px`}
              color="black"
              textAlign="center"
              w="100%"
            >
              <Box
                fontWeight="bold"
                fontSize={`${fontSizeHeading}px`}
                w="100%"
                overflow="hidden"
                sx={{
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  hyphens: "auto",
                }}
              >
                {heading}
              </Box>
              <Box
                fontSize={`${fontSizeBody}px`}
                w="100%"
                overflow="hidden"
                sx={{
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  hyphens: "auto",
                }}
              >
                {body}
              </Box>
            </VStack>
          </Box>
        </foreignObject>
      </svg>
    </Box>
  );
};

export default Hexa;