import { Box, Center, ChakraProvider, ColorModeScript, Flex, useColorModeValue } from "@chakra-ui/react";
import { theme } from "./styles/theme";
import TopBar from "layout/TopBar/TopBar";
import { Sidebar } from "layout/Sidebar";
import { APIProvider } from '@vis.gl/react-google-maps';
import { MojoRouter } from "./MojoRouter";

const GOOGLE_MAPS_LIBRARIES: ("places" | "visualization" | "drawing" | "geometry" | "localContext")[] = ["places"];

function App() {
    const bg = useColorModeValue('white.100', 'black.400')

    return (
        <ChakraProvider theme={theme}>
            <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                libraries={GOOGLE_MAPS_LIBRARIES}
            >
                <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                <Box className="app-wrapper">
                    <TopBar />
                    <Sidebar />
                    <Box className="app-content--container">
                        <MojoRouter />
                    </Box>
                    {/* <Box>
                        <Footer />
                    </Box> */}
                </Box>
            </APIProvider>
        </ChakraProvider>
    );
}
export default App;

