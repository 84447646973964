import {
    Button,
    FormControl,
    Flex,
    Card,
    useColorModeValue,
    Switch,
    FormLabel,
    Select,
    Box,
} from '@chakra-ui/react';
import { components } from 'api/typesgen';
import { Select as Multi, useSelect } from 'components/Select';
import CreatableSelect from 'react-select/creatable';
import { ReactElement, useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FloatingLabelInput } from '../../../components/FloatingLabelInput';
import { LocationMap } from '../../../components/LocationMap';

import './ClientForm.scss';
import allStatesData from 'data/allStates';
import AddressAutocomplete from 'components/AddressAutocomplete';

const labelProps = { transform: 'scale(0.85) translateY(-24px)' };

type TenantWithTimezone = components['schemas']['Tenant'] & {
    timeZone?: string;
    timeZoneId?: string;
    rawOffset?: string;
    dstOffset?: string;
    clientPma?: string;
    pmaZipCodes?: string[];
};

export type ClientFormProps = {
    submit: (data: TenantWithTimezone) => Promise<any>;
    values?: TenantWithTimezone;
    defaultValues?: Partial<TenantWithTimezone>;
    btnTitle: string;
    btnIcon: ReactElement;
    formTitle: string;
    onlyRequired?: boolean;
    isSubmitting?: boolean;
    isLoading?: boolean;
    clientTypesData: any[];
    brandsData: any[];
    clientGroupsData: any[];
};

export function ClientForm({
    submit,
    values,
    defaultValues,
    btnTitle,
    btnIcon,
    isLoading,
    isSubmitting,
    onlyRequired,
    clientTypesData,
    brandsData,
    clientGroupsData,
}: ClientFormProps) {
    const [isFormDirty, setIsFormDirty] = useState(false);
    const statesData = allStatesData;
    const bg = useColorModeValue('white.100', 'black.100');
    const color = useColorModeValue('black.700', 'white.100');
    const [activeFlag, setActiveFlag] = useState(values === undefined ? true : values.activeFlag);
    const [pmaZipCodes, setPmaZipCodes] = useState<Array<{ label: string; value: string }>>(
        (values?.pmaZipCodes || []).map(zip => ({ label: zip, value: zip }))
    );

    // Track if we're currently submitting to prevent double submissions
    const isSubmittingRef = useRef(false);

    // Track the latest values to prevent unnecessary resets
    const latestValuesRef = useRef(values);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        setValue,
        reset,
        control
    } = useForm<NonNullable<typeof values>>({
        values,
        defaultValues,
    });

    const { registerSelect } = useSelect<NonNullable<typeof values>>({
        values,
        setValue,
    });

    useEffect(() => {
        // Only update form if values have actually changed
        if (values !== latestValuesRef.current) {
            latestValuesRef.current = values;
            reset(values);
            setActiveFlag(values?.activeFlag ?? true);
            setPmaZipCodes((values?.pmaZipCodes || []).map(zip => ({ label: zip, value: zip })));
        }
    }, [values, reset]);

    useEffect(() => {
        register('TenantTypeId');
        register('TenantGroupId');
        register('TenantBrandIds');
        register('timeZone');
        register('clientPma');
        register('pmaZipCodes');
    }, [register]);

    const handleFormSubmit = async (formData: any) => {
        if (isSubmittingRef.current || !isFormDirty) {
            return;
        }

        try {
            isSubmittingRef.current = true;
            const result = await submit(formData);
            setIsFormDirty(false);
            // Update the latest values ref with the submitted data
            latestValuesRef.current = result;
        } finally {
            isSubmittingRef.current = false;
        }
    };

    const brandOptions = brandsData
        ? brandsData.map(({ BrandId, name }) => ({ label: name, value: BrandId }))
        : [];

    const clientTypeOptions = clientTypesData
        ? clientTypesData.map(({ TenantTypeId, name }) => ({
            label: name,
            value: TenantTypeId,
        }))
        : [];

    const clientGroupOptions = clientGroupsData
        ? clientGroupsData.map(({ id, name }) => ({
            label: name,
            value: id,
        }))
        : [];

    const statesOptions = statesData
        ? statesData.map(({ name, value }) => ({ label: name, value: value }))
        : [];

    const isInvalid =
        !!errors.name ||
        !!errors.TenantTypeId ||
        !!errors.TenantBrandIds ||
        !!errors.TenantGroupId;

    const buttonEnabled =
        isFormDirty && !isInvalid && !isLoading && !isSubmitting;

    const buttonIsLoading = isLoading || isSubmitting;
    const buttonLoadingText = isLoading ? 'LOADING' : undefined;

    return (
        <Card p={18} bg={bg} w={500}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <FormControl className='client-form' isInvalid={isInvalid}>
                    <div className='client-form-subheader'>
                        <h2>CLIENT DETAILS</h2>
                        <div className='left-form-switchs'>
                            {onlyRequired ? (
                                <></>
                            ) : (
                                <>
                                    <FormLabel htmlFor='client-active' mb='0'>
                                        Active?
                                    </FormLabel>
                                    <Switch
                                        size='md'
                                        isChecked={activeFlag}
                                        {...register('activeFlag')}
                                        onChange={(e) => {
                                            setIsFormDirty(true);
                                            setActiveFlag(e.target.checked);
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className='client-form-body'>
                        <FloatingLabelInput
                            placeholder='Client Name'
                            isRequired={true}
                            isInvalid={!!errors.name}
                            {...register('name', {
                                required: 'This is required',
                                minLength: {
                                    value: 4,
                                    message: 'Minimum length should be 4',
                                },
                            })}
                            onChange={(e) => {
                                setIsFormDirty(e.target.value !== defaultValues?.name);
                            }}
                        />

                        <FormControl variant='floating' size='md' isRequired>
                            <Flex
                                width={'100%'}
                                gap={'10px'}
                                justifyContent={'space-between'}
                            >
                                <Select
                                    color={color}
                                    placeholder='Select...'
                                    {...register('TenantGroupId')}
                                    className='client-group'
                                    onChange={(e) => {
                                        setIsFormDirty(
                                            e.target.value !== defaultValues?.TenantGroupId
                                        );
                                    }}
                                >
                                    {clientGroupOptions.map((o) => (
                                        <option key={o.value} value={o.value}>{o.label}</option>
                                    ))}
                                </Select>
                                <FormLabel
                                    style={{ paddingRight: '8px' }}
                                    bg={bg}
                                    color={color}
                                >
                                    Client Group
                                </FormLabel>
                            </Flex>
                        </FormControl>

                        <FormControl className='client-brands-container' variant='floating' size='md' isRequired>
                            <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
                                Client Brands
                            </FormLabel>
                            <Multi
                                id='myMulti'
                                //placeholder='Client Brands'
                                isInvalid={!!errors.TenantTypeId}
                                {...registerSelect('TenantBrandIds', {
                                    transform: (t) => {
                                        setIsFormDirty(true);
                                        return t;
                                    },
                                    options: brandOptions,
                                    isMulti: true,
                                    required: true,
                                })}
                            />
                        </FormControl>

                        <FormControl className='client-type-container' variant='floating' size='md' isRequired>
                            <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
                                Client Type
                            </FormLabel>
                            <Select
                                placeholder='Select...'
                                color={color}
                                {...register('TenantTypeId')}
                                onChange={(e) => {
                                    setIsFormDirty(
                                        e.target.value !== defaultValues?.TenantTypeId
                                    );
                                }}
                            >
                                {clientTypeOptions.map((o) => (
                                    <option key={o.value} value={o.value}>{o.label}</option>
                                ))}
                            </Select>
                        </FormControl>

                        <FloatingLabelInput
                            placeholder='Client Website'
                            {...register('mainWebsiteUrl')}
                            onChange={(e) => {
                                setIsFormDirty(
                                    e.target.value !== defaultValues?.mainWebsiteUrl
                                );
                            }}
                        />

                        <FloatingLabelInput
                            placeholder='Client NickName'
                            {...register('tenantNickName')}
                            onChange={(e) => {
                                setIsFormDirty(
                                    e.target.value !== defaultValues?.tenantNickName
                                );
                            }}
                        />

                        <FloatingLabelInput
                            placeholder='Client Slogan'
                            {...register('tenantSlogan')}
                            onChange={(e) => {
                                setIsFormDirty(
                                    e.target.value !== defaultValues?.tenantSlogan
                                );
                            }}
                        />

                        <FloatingLabelInput
                            placeholder='Client phone Number'
                            {...register('phone')}
                            onChange={(e) => {
                                setIsFormDirty(e.target.value !== defaultValues?.phone);
                            }}
                        />

                        <FloatingLabelInput
                            placeholder='Client Code'
                            {...register('tenantCode')}
                            onChange={(e) => {
                                setIsFormDirty(
                                    e.target.value !== defaultValues?.tenantCode
                                );
                            }}
                        />

                        <AddressAutocomplete
                            className='client-address-autocomplete'
                            placeholder='Client Address'
                            control={control}
                            name='address'
                            bg={bg}
                            color={color}
                            onChange={(addressData) => {
                                setValue('address', addressData.address);
                                setValue('city', addressData.city);
                                setValue('state', addressData.state);
                                setValue('zip', addressData.zip);
                                setValue('lat', addressData.lat?.toString() ?? '');
                                setValue('lng', addressData.lng?.toString() ?? '');
                                if (addressData.timezone) {
                                    // setValue('timeZoneId', addressData.timezone.timeZoneId);
                                    setValue('timeZone', addressData.timezone.timeZoneName);
                                    // setValue('rawOffset', addressData.timezone.rawOffset.toString());
                                    // setValue('dstOffset', addressData.timezone.dstOffset.toString());
                                }
                                setIsFormDirty(true);
                            }}
                        />

                        <FloatingLabelInput
                            placeholder='Client City'
                            {...register('city')}
                            onChange={(e) => {
                                setIsFormDirty(e.target.value !== defaultValues?.city);
                            }}
                        />

                        <FormControl className='client-state-container' variant='floating' size='md' isRequired>
                            <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
                                State
                            </FormLabel>
                            <Select
                                placeholder='Select...'
                                color={color}
                                {...register('state')}
                                onChange={(e) => {
                                    setIsFormDirty(e.target.value !== defaultValues?.state);
                                }}
                            >
                                {statesOptions.map((o) => (
                                    <option key={o.value} value={o.value}>{o.label}</option>
                                ))}
                            </Select>
                        </FormControl>

                        <FloatingLabelInput
                            isRequired
                            placeholder='Client Zip (5 digits)'
                            {...register('zip')}
                            onChange={(e) => {
                                setIsFormDirty(e.target.value !== defaultValues?.zip);
                            }}
                        />

                        <FloatingLabelInput
                            placeholder='Client Dealership USPS'
                            {...register('tenantDealerShipUSPS')}
                            onChange={(e) => {
                                setIsFormDirty(
                                    e.target.value !== defaultValues?.tenantDealerShipUSPS
                                );
                            }}
                        />

                        <FloatingLabelInput
                            className='client-lat'
                            disabled={true}
                            placeholder='Client Lat'
                            {...register('lat')}
                            onChange={(e) => {
                                setIsFormDirty(e.target.value !== defaultValues?.lat);
                            }}
                        />

                        <FloatingLabelInput
                            className='client-lng'
                            disabled={true}
                            placeholder='Client Lng'
                            {...register('lng')}
                            onChange={(e) => {
                                setIsFormDirty(e.target.value !== defaultValues?.lng);
                            }}
                        />

                        <FloatingLabelInput
                            className='client-timezone'
                            isDisabled={true}
                            placeholder='Timezone'
                            {...register('timeZone')}
                        />
                        
                        <FormControl className='client-pma-container' variant='floating' size='md'>
                            <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
                                PMA Zip Codes
                            </FormLabel>
                            <CreatableSelect
                                id='pmaZipCodes'
                                placeholder='Enter or paste zip codes'
                                isClearable
                                isMulti
                                value={pmaZipCodes}
                                onChange={(newValue: any) => {
                                    const newZipcodes = newValue || [];
                                    setPmaZipCodes(newZipcodes);
                                    setValue('pmaZipCodes', newZipcodes.map(v => v.value));
                                    setIsFormDirty(true);
                                }}
                                onInputChange={(inputValue: string) => {
                                    if (inputValue.includes(',') || inputValue.includes('\n') || inputValue.includes(' ')) {
                                        const zips = inputValue
                                            .split(/[\n,\s]+/)
                                            .map(zip => zip.trim())
                                            .filter(zip => zip.length > 0);
                                        
                                        const newZipcodes = [
                                            ...pmaZipCodes,
                                            ...zips.map(zip => ({ label: zip, value: zip }))
                                        ];
                                        setPmaZipCodes(newZipcodes);
                                        setValue('pmaZipCodes', newZipcodes.map(v => v.value));
                                        setIsFormDirty(true);
                                        return '';
                                    }
                                    return inputValue;
                                }}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        minHeight: '45px'
                                    }),
                                    multiValue: (base) => ({
                                        ...base,
                                        backgroundColor: '#E2E8F0',
                                        borderRadius: '4px',
                                        margin: '2px 4px',
                                        padding: '2px 2px 2px 6px',
                                    }),
                                    multiValueLabel: (base) => ({
                                        ...base,
                                        color: '#2D3748',
                                        fontSize: '14px',
                                        padding: '2px',
                                    }),
                                    multiValueRemove: (base) => ({
                                        ...base,
                                        color: '#4A5568',
                                        marginLeft: '6px',
                                        ':hover': {
                                            backgroundColor: '#CBD5E0',
                                            color: '#2D3748',
                                        },
                                    }),
                                }}
                            />
                        </FormControl>

                        {/* <FloatingLabelInput
                            className='client-timezone-id'
                            disabled={true}
                            placeholder='Timezone ID'
                            {...register('timeZoneId')}
                        />

                        <FloatingLabelInput
                            className='client-tz-raw-offset'
                            disabled={true}
                            placeholder='Timezone Raw Offset'
                            {...register('rawOffset')}
                        />

                        <FloatingLabelInput
                            className='client-tz-dst-offset'
                            disabled={true}
                            placeholder='Timezone Dst Offset'
                            {...register('dstOffset')}
                        /> */}

                        {/* Optional fields section */}
                        {/* {!onlyRequired && (
                            <>

                            </>
                        )} */}
                    </div>
                    <div className='client-form-footer'>
                        <Button
                            isLoading={buttonIsLoading}
                            isDisabled={!buttonEnabled}
                            leftIcon={btnIcon}
                            loadingText={buttonLoadingText}
                            variant='mojoPrimary'
                            type='submit'
                        >
                            {btnTitle}
                        </Button>
                    </div>
                </FormControl>
            </form>
        </Card>
    );
}

