import { Select, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export default function SelectFilter({
    column: { filterValue, setFilter, preFilteredRows, id, filterPlaceholder, longerWidth = 0, className = ""},
    valueMap = {},
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {       
        const options = new Set();
        preFilteredRows.forEach((row) => {
              options.add(row.values[id]);
        });
        
        // Sort and filter any falsy values
        var sortedOptions;
        if (options.has(true) || options.has(false)) {
            
            sortedOptions = [true, false];
        } else {
            sortedOptions = Array.from(options ?? []).filter(Boolean).sort((a: any, b: any) => {
                //if (a.toLowerCase() === "all") return -1; // "All" comes first
                //if (b.toLowerCase() === "all") return 1; // "All" comes first
                //if (a.toLowerCase() === "other") return 1; // "Other" comes last
                //if (b.toLowerCase() === "other") return -1; // "Other" comes last
                return a.localeCompare(b);
            });
        }
        // @ts-ignore
        //return sortedOptions;
        const result = (sortedOptions ?? []).filter(
          (x) => x !== null
        );

        return result;
    }, [id, preFilteredRows]);
    const bg = useColorModeValue('white.100', 'black.200')
    const color = useColorModeValue('black.200', 'white.100')
    // Render a multi-select box
    return (
        <Select
            placeholder={filterPlaceholder || `Select...`}
            value={filterValue}
            size={"sm"}
            color={color}
            bg={bg}
            borderRadius='md'
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            width='200px'
            maxWidth="100%"
            className={className}
        >
            {/* <option value="">All</option> */}
            {options?.map((option:any, i:number) => (
                <option key={i} value={option}>
                    {(valueMap && valueMap[option]) || option}
                </option>
            ))}
        </Select>
    );
}
