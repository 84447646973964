import { Box, Center, Flex, Image, useToast } from '@chakra-ui/react';
import { useMojoEffect } from 'api/useMojoEffect';
import { ClientListDataTable } from '../ClientListDataTable';
import { useCallback, useEffect, useState } from 'react';
import './ClientsList.scss';
import { useNavigate } from 'hooks/useNavigate';
import { connect } from 'react-redux';
import LoadingScreen from 'components/Loading/LoadingScreen';

function ClientsList(props) {
  const [fetchError, setError] = useState(null as any);
  const [clients, setData] = useState <any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const { runWithId: runDelete } = useMojoEffect(`/api/v1/clients/`, 'delete');
  const { runWithId:getClients } = useMojoEffect(`/api/v1/clients?`, 'get');

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const [data, error] = await getClients(`groupId=${props.groupId}`);
      if (error === null) {
        setData(data);
      } else {
        setError(error);
      }
      setLoading(false);
    };
    fetch();
  }, [props.groupId]);

  const toast = useToast();

  const deleteClient = useCallback(
    async (tenantId: string) => {
      const [delResult, error] = await runDelete(tenantId);
      if (error === null) {
        toast({
          title: 'Client deleted',
          status: 'success',
        });
        setLoading(true);
      const [data, error] = await getClients(`groupId=${props.groupId}`);
      if (error === null) {
        setData(data);
      } else {
        setError(error);
      }
      setLoading(false);
      } else {
        toast({
          title: 'Failed to delete client',
          status: 'error',
        });
      }
    },
    [getClients, props.groupId, runDelete, toast]
  );

  const navigate = useNavigate();

  const addEditUsers = (id: string) => {
    const client = clients?.find((d) => d.TenantId === id);
    if (client === undefined) {
      return;
    }
    navigate(`/users/tenant/${client?.name}/${client.TenantId}`);
  };

  if (isLoading) {
    return <LoadingScreen isLoading={isLoading} />;
  }

  if (fetchError !== null) {
    return <Center>{fetchError.message}</Center>;
  }

  return (
    <div className='client-list'>
      <ClientListDataTable
        data={clients}
        error={false}
        deleteClient={deleteClient}
        addEditUsers={addEditUsers}
      />
    </div>
  );
}
const select = (state) => {
  return { groupId: state.app.selectedGroupId };
};
export default connect(select)(ClientsList);