import { Box, Center, Flex, Image, Text, Heading, HStack, Card, CardHeader, CardBody, Icon, Link, useColorModeValue, Spinner } from '@chakra-ui/react';
import './AdminPage.scss';
import { NavLink } from "react-router-dom";
import { SlOrganization } from "react-icons/sl";
import { HiOutlineUserGroup } from "react-icons/hi";
import { TbClock } from "react-icons/tb";
import { Divider } from 'antd';
import { LiaHandshake } from "react-icons/lia";
import { BsPeople } from "react-icons/bs";
import { MdOutlineHealthAndSafety, MdKey } from "react-icons/md";
import { useMojoFetch } from 'api/useMojoFetch';
import { useMemo } from 'react';
import { FaKey } from "react-icons/fa";

// New Card Component
const AdminCard = ({ to, title, isLoading, count, icon, countSpinner }) => (
    <NavLink to={to} className="admin-card--link">
        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
            <CardHeader>
                <Flex className="card-heading">{title}</Flex>
            </CardHeader>
            <Flex className="admin-card-count">{countSpinner(isLoading, count)}</Flex>
            <Divider />
            <CardBody>
                <Flex justify="center" align="center" height="100%">
                    <Icon as={icon} boxSize={20} />
                </Flex>
            </CardBody>
        </Card>
    </NavLink>
);


function AdminPage() {
  

    const { data: organizationData, isLoading: organizationLoading } = useMojoFetch("/api/v1/Organizations/", "get");
    const organizationCount = organizationData ? organizationData.length : 0;

    const { data: groupData, isLoading: groupLoading } = useMojoFetch("/api/v1/Groups/", "get");
    const groupCount = groupData ? groupData.length : 0;

    const { data: userData, isLoading: userLoading } = useMojoFetch("/api/v1/Users/", "get");
    const userCount = userData ? userData.length : 0;

    const { data: partnerData, isLoading: partnerLoading } = useMojoFetch("/api/v1/Vendors/all/", "get");
    const partnerCount = partnerData ? partnerData.length : 0;

    const { data: agencyData, isLoading: agencyLoading } = useMojoFetch("/api/v1/Agencies/", "get");
    const agencyCount = agencyData ? agencyData.length : 0;


    const countSpinner = (isLoading, count) => {
        if (isLoading) {
            return <Spinner size="sm" />;
        }
        return count;
    };
    
    return (
        <Box className='admin-page--wrapper'>
            <Flex className="admin-page--header">
                <Flex className="admin-header--left">
                <Heading className="admin-heading">ADMIN</Heading>
                </Flex>
            </Flex>
            <Flex className="admin-page--body">
                <Flex
                    className="admin-nav--links"
                    direction="column"
                    align="center"
                    justify="flex-start"
                    p={2}
                    mb="15px"
                    as="nav"
                >
                    <AdminCard to="/agencies" title="Agencies" isLoading={agencyLoading} count={agencyCount} icon={TbClock} countSpinner={countSpinner} />
                    <AdminCard to="/groups" title="Groups" isLoading={groupLoading} count={groupCount} icon={HiOutlineUserGroup} countSpinner={countSpinner} />
                    <AdminCard to="/organizations" title="Organizations" isLoading={organizationLoading} count={organizationCount} icon={SlOrganization} countSpinner={countSpinner} />
                    <AdminCard to="/partners" title="Partners" isLoading={partnerLoading} count={partnerCount} icon={LiaHandshake} countSpinner={countSpinner} />
                    <AdminCard to="/users" title="Users" isLoading={userLoading} count={userCount} icon={BsPeople} countSpinner={countSpinner} />
                    <AdminCard to="/home" title="Admin Health" isLoading={false} count={0} icon={MdOutlineHealthAndSafety} countSpinner={countSpinner} />
                    <AdminCard to="/credentials" title="Mojo Credentials" isLoading={false} count={0} icon={MdKey} countSpinner={countSpinner} />
                </Flex>
            </Flex>
        </Box>
    );
}

export default AdminPage;