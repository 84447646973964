import { Spinner, Heading, Box, Button, Center, Badge, Select, Flex, VStack, Text, Icon } from '@chakra-ui/react';
import { FaSync } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useMojoFetch } from 'api/useMojoFetch';
import { useEffect, useState } from 'react';
import Hexa from 'components/Cards/Hexa';
import HexaGrid from 'components/Cards/HexaGrid';


function ClientDemographics() {
  const [censusComponents, setCensusComponents] = useState([] as any)
  const [selectedZipCode, setSelectedZipCode] = useState<string | undefined>(undefined);
  const [selectedZipData, setSelectedZipData] = useState()
  const { tenantId } = useParams();
  const { data: tenantData } = useMojoFetch(`/api/v1/Clients/${tenantId}`,
    "get");
  const [refreshKey, setRefreshKey] = useState(0);


  useEffect(() => {
    const getCensus = async () => {
      if (tenantData) {
        if(tenantData?.pmaZipCodes){
          await getCensusData(); // Refetch data when tenantData changes
          await getCensusAvgData();
        }
      }
    }
    getCensus()
  }, [tenantData]);


  const { data: censusData, run: getCensusData, isLoading } = useMojoFetch(
    `/api/v1/Census/data?tenantId=${tenantId}&year=2023&zipcodes=${tenantData?.pmaZipCodes}`,
    'GET',
    null,
    true // Make sure shouldRun is true when tenantData is available
  );

  const { data: censusAvgData, run: getCensusAvgData } = useMojoFetch(
    `/api/v1/Census/averages?tenantId=${tenantId}&year=2023&zipcodes=${tenantData?.pmaZipCodes}`,
    'GET',
    null,
    true // Make sure shouldRun is true when tenantData is available
  );

  const hexGrid = {
    "year": {row: 0, index: 0}, 
    "totalPopulation" : {row: 0, index: 1},
    "malePopulation" : {row: 0, index: 2}, 
    "femalePopulation" : {row: 0, index: 3},
    "occupiedHousingUnits": {row: 1, index: 3 },
    "totalHouseholds" : {row: 1, index: 1},
    "renterOccupiedHousingUnits" : {row: 1, index: 2},
    "medianHouseholdIncome": {row: 2, index: 1},
    "employedPopulation": {row: 2, index: 2}, 
    "unemployedPopulation": {row: 2, index: 3}, 
    "notInLaborForce": {row: 2, index: 4},
    "income200kOrMore": {row: 3, index: 0},
    "income150kTo199k": {row: 3, index: 1},
    "income100kTo149k": {row: 3, index: 2},
    "income75kTo99k": {row: 3, index: 3},
    "income50kTo74k": {row: 3, index: 4},
    "income35kTo49k": {row: 4, index: 0},
    "income25kTo34k": {row: 4, index: 1},
    "income15kTo24k": {row: 4, index: 2},
    "income10kTo14k": {row: 4, index: 3},
    "incomeLessThan10k": {row: 4, index: 4},

    "averageTotalPopulation" : {row: 0, index: 1},
    "averageMalePopulation" : {row: 0, index: 2}, 
    "averageFemalePopulation" : {row: 0, index: 3},
    "averageOccupiedHousingUnits": {row: 1, index: 3 },
    "averageTotalHouseholds" : {row: 1, index: 1},
    "averageRenterOccupiedHousingUnits" : {row: 1, index: 2},
    "averageMedianHouseholdIncome": {row: 2, index: 1},
    "averageEmployedPopulation": {row: 2, index: 2}, 
    "averageUnemployedPopulation": {row: 2, index: 3}, 
    "averageNotInLaborForce": {row: 2, index: 4},
    "averageIncome200kOrMore": {row: 3, index: 0},
    "averageIncome150kTo199k": {row: 3, index: 1},
    "averageIncome100kTo149k": {row: 3, index: 2},
    "averageIncome75kTo99k": {row: 3, index: 3},
    "averageIncome50kTo74k": {row: 3, index: 4},
    "averageIncome35kTo49k": {row: 4, index: 0},
    "averageIncome25kTo34k": {row: 4, index: 1},
    "averageIncome15kTo24k": {row: 4, index: 2},
    "averageIncome10kTo14k": {row: 4, index: 3},
    "averageIncomeLessThan10k": {row: 4, index: 4}
  }

  const formatKey = (key: string): string => {
    const newKey =  key
      .replace(/(?<=\D)(?=\d)|(?<=[A-Za-z])(?=\d)/g, ' ') // Split before the first number and between letters and numbers
      .replace(/([A-Z])/g, ' $1') // Split on capital letters
      .trim()                     // Remove leading/trailing spaces
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    return newKey
  }


  const convertKeysToHumanReadable = (str) => {
    let newStr = formatKey(str)
    return newStr
  }

  const buildCensusComponents = () => {
    const headBodyColor = '#73CEE2'
    const outlineColor = '#62fa0d'
    const components = censusData.map((data) => {
      const components = Object.keys(data).map((key, idx) => {
        const gridData = hexGrid[key];
        if (gridData && typeof gridData === 'object' && 'row' in gridData && 'index' in gridData) {
          const { row, index } = gridData;
            return (<Hexa 
                    key={key + idx + data['zip']} 
                    heading={convertKeysToHumanReadable(key)} 
                    body={data[key]} 
                    row={row} 
                    index={index} 
                    bodyColor= {headBodyColor}
                  />);
        } else {
          return null;
        }
      });
      return {zip: data?.zip, components}
    }); 
    
    const avgComponents = censusAvgData.map((data) => {
      const components = Object.keys(data).map((key, idx) => {
        const gridData = hexGrid[key];
        if (gridData && typeof gridData === 'object' && 'row' in gridData && 'index' in gridData) {
          const { row, index } = gridData;
            return (<Hexa 
                      key={key + idx + data['zip']}
                      heading={convertKeysToHumanReadable(key)} 
                      body={data[key]} 
                      row={row} 
                      index={index}
                      bodyColor={headBodyColor}
                    />);
        } else {
          console.warn(`No valid grid data found for key: ${key}`);
          return null;
        }
      });
      return {zip: 'avg', components}
    }); 
    setCensusComponents([...components, ...avgComponents]); // Set the chunked components
  };

  useEffect(() => {
    if(censusData && censusData.length > 0){
      buildCensusComponents()
    }
  }, [censusData, censusAvgData])

  const { run: postCensusCreate } = useMojoFetch('/api/v1/Census/create',
    'POST',
    { tenantId, year: "2023", zipcodes: tenantData?.pmaZipCodes },
    true
  );

  if (!tenantId || isLoading) {
    return (
      <Center h="100%">
        <Spinner size="xl" />
      </Center>
    );
  }




  const handleSelect = (e) =>{
    setSelectedZipCode(e.target.value)
    const obj = censusComponents.find((d) => d.zip === e.target.value)
    setSelectedZipData(prev => obj?.components)
    setRefreshKey(prev => prev + 1)
  }

  return (
    <div>
      <Box 
        bg="gray.100" 
        p={4} 
        borderBottom="1px" 
        borderColor="gray.200"
      >
        <Heading size="lg" color="gray.800">
          Demographics for {tenantData?.name}
        </Heading>
      </Box>
  
      <Box p={{ base: 4, md: 10 }} maxW="1200px" >
          <Box
            p={6}
            bg="white"
            borderRadius="md"
            boxShadow="sm"
            w={{ base: '100%', md: '30%' }}
            border="1px"
            borderColor="gray.200"
          >
            <VStack align="start" spacing={4}>
              <Text fontWeight="bold" fontSize="lg" color="gray.700">
                Primary Market Area
              </Text>
              <Flex flexWrap="wrap" gap={2}>
                {tenantData?.pmaZipCodes?.map((zipCode, index) => (
                  <Badge
                    key={index}
                    px={2}
                    py={1}
                    fontSize="md"
                    variant="subtle"
                    colorScheme="blue"
                    borderRadius="full"
                  >
                    {zipCode}
                  </Badge>
                ))}
              </Flex>
              <Button
                onClick={postCensusCreate}
                colorScheme="blue"
                size="md"
                w="full"
                leftIcon={<Icon as={FaSync} />}
              >
                Refresh Data
              </Button>
              <Select
                placeholder="Select Zip Code or Averages"
                value={selectedZipCode}
                onChange={(e) => handleSelect(e)}
                size="md"
                borderColor="gray.300"
                _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px blue.500' }}
              >
                <option value="avg">Averages</option>
                {tenantData?.pmaZipCodes?.map((zipCode, index) => (
                  <option key={index} value={zipCode}>
                    {zipCode}
                  </option>
                ))}
              </Select>
            </VStack>
          </Box>

          {selectedZipData && (
            <Box
              w={{ base: '100%', md: '70%' }}
              p={6}
              bg="white"
              borderRadius="md"
              boxShadow="sm"
            >
              <HexaGrid 
                height={6} 
                width={6} 
                key={refreshKey} 
                components={selectedZipData} 
              />
            </Box>
          )}
      </Box>
    </div>
  );
}

export default ClientDemographics;
