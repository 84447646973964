import React, {useState} from "react";
import Hexa from 'components/Cards/Hexa';

export const HexaGrid = ({components, width, height, ...props}) => {
    const [state, dispatch] = React.useReducer(reducer, {
        board: createBoard(components, width, height),
        currentSide: "A"
      });
      return (
        <div style={{ width: "1000px"}} key={props?.key} >
          <div>
            {state.board.map((row, idx) => {
              const offset = idx % 2 === 1 ? "95px" : "0px"; // Set offset based on odd index
              return (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    paddingLeft: offset,
                    marginBottom: "-40px"
                  }}
                >
                {row.map((elem, index) => {
                    if (!elem) {
                        return <div key={`blank-${idx}-${index}`}> <Hexa row={idx} index={index}/> </div>;
                    }
                    return (                    
                    <div key={`item-${idx}-${index}`}>
                        {elem}
                    </div>);
                })}
                </div>
              );
            })}
          </div>
        </div>
      );

}


function reducer(state, action) {
    switch (action.type) {
      case "put":
        return {
          ...state,
          board: put(
            state.board,
            action.payload.rowIndex,
            action.payload.cellIndex,
            state.currentSide
          ),
          currentSide: changeSide(state.currentSide)
        };
      default:
        return state;
    }
  }


  function createBoard(items, height, width) {
    // Transform items into a 2D array
    const rows: Array<Array<any>> = Array.from({ length: height }, () => Array(width).fill(null)); // Initialize rows based on height and width

    items.forEach(item => {
        const { row, index } = item?.props || { row: null, index: null }; // Default to null if props are undefined
        if (row === null || index === null) {
            // Return a blank hexagon or placeholder
            return; // Skip to the next item if row or index is null
        }
        if (row < height && index < width) { // Ensure row and index are within bounds
            rows[row][index] = item; // Place the item in the correct position
        }
    });

    return rows; // Return the rows instead of items
  }
  
  function put(board, rowIndex, cellIndex, side) {
    const newBoard = board.map((row) => [...row]);
    newBoard[rowIndex][cellIndex] = side;
    return newBoard;
  }
  
  function changeSide(side) {
    return side === "A" ? "B" : "A";
  }

export default HexaGrid