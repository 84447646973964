import { connect } from 'react-redux';
import { useMojoFetch } from 'api/useMojoFetch';
import Goals from './Goals/Goals';
import { useMojoEffect } from 'api/useMojoEffect';
import { useEffect, useState } from 'react';
import LoadingScreen from 'components/Loading/LoadingScreen';

function GoalsIntro(props) {
  const { data: goalTypes, isLoading: gtLoading } = useMojoFetch(
    '/api/v1/goaltypes/all',
    'get'
  );

  const { data: departments, isLoading: dLoading } = useMojoFetch(
    '/api/v1/goals/departments',
    'get'
  );

  const { runWithId: getClients } = useMojoEffect(
    `/api/v1/clients?groupId=`,
    'get'
  );

  const [clients, setClients] = useState([]);
  const [cnLoading, setCnLoading] = useState(false);
  const [isUploading, setUploading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setCnLoading(true);
      const [clients, client_error] = await getClients(props.groupId);
      if (client_error === null) {
        setClients(clients);
        const tenantIds = clients
          .filter((c) => c.TenantId)
          .map((x) => x.TenantId);
        if (!tenantIds.includes(localStorage.getItem('location'))) {
          setUploading(false);
        }
      }
      setCnLoading(false);
    };
    fetch();
  }, [props.groupId]);

  if (cnLoading || gtLoading || dLoading) {
    return <LoadingScreen isLoading={true} />;
  }

  return (
    <Goals
      loc={localStorage.getItem('location') || ''}
      loc_name={localStorage.getItem('location_name') || ''}
      clients={clients}
      goalTypes={goalTypes.map((g) => {
        return { id: g.GoalTypeId, name: g.description };
      })}
      departments={departments}
      isUploading={isUploading}
      setUploading={setUploading}
    />
  );
}
const select = (state) => {
  return { groupId: state.app.selectedGroupId };
};
export default connect(select)(GoalsIntro);
