import { MdGridView } from "react-icons/md";
import { AiOutlineDollar } from "react-icons/ai";
import { GoGraph, GoPeople, GoGoal } from "react-icons/go";
import { MdOutlineAdminPanelSettings } from "react-icons/md";


import Sidenav from "./SideNav";


const routes = [
  { to: '/', icon: MdGridView, label: 'Dashboard' },
  { to: '/clients', icon: GoPeople, label: 'Clients' },
  { to: '/budgets', icon: AiOutlineDollar, label: 'Budgets' },
  { to: '/goals', icon: GoGoal, label: 'Goals' },
  { to: '/reports', icon: GoGraph, label: 'Reports' },
  { to: '/admin', icon: MdOutlineAdminPanelSettings, label: 'Admin', roles:['Super Admin'] },
];

export function Sidebar() {
  return (
      <Sidenav navItems={routes}/>
  );
}

