import React from 'react';
import { ChakraProvider, Box, Spinner, Center, Text, Image } from '@chakra-ui/react';
import logo from "../../logo-teal.png"

const LoadingScreen = ({ isLoading }) => {
    if (isLoading) {
        return (
            <ChakraProvider>
                <Box h="50vh" bg="black.100">
                    <Center h="100%">
                        <Box textAlign="center">
                            <Image src={logo} alt="Mojo Platform" />
                            <Spinner size="xl" color="teal.500" />
                            <Text mt={4} fontSize="lg" color="teal.600">
                                Loading, please wait...
                            </Text>
                        </Box>
                    </Center>
                </Box>
            </ChakraProvider>
        );
    }
    return null; // Or your normal content
};

export default LoadingScreen;