import React, { useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";

import {
  Button,
  Flex,
  Box,
  Heading,
  Input,
  IconButton,
  useToast,
  Select,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { SettingsIcon, DeleteIcon } from "@chakra-ui/icons";
import CodeEditorModal from "components/CodeEditorModal";
import { TenantIntegrationData } from "data/allIntegrations";
import { updateRulesProps } from "modules/client/AddEditIntegrationForm/GoogleOauthButton";
import { defaultRule } from "hardcoded_data";

export type RuleType = {
  id: number;
  name: string;
  rule: string;
  integration: string;
};

export default function EditRulesModal(props: {
  integrations: TenantIntegrationData[];
  modalOpen: boolean;
  code: string;
  setModalOpen: (open: boolean) => void;
  setCode: (open: string) => void;
  refetch: () => void;
}) {
  const codeEditorRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useClickAway(codeEditorRef, () => {
    if (props.modalOpen && !loading) {
      props.setModalOpen(false);
    }
  });

  return (
    <section
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(140, 130, 130, 0.1)",
        position: "fixed",
        top: "0",
        left: "0",
        justifyContent: "center",
        alignItems: "center",
        display: props.modalOpen ? "flex" : "none",
        zIndex: 99999999,
      }}
    >
      <div
        ref={codeEditorRef}
        style={{
          height: "500px",
          width: "750px",
          overflow: "hidden",
          border: "1px solid rgba(225, 225, 225, 0.9)",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "5px 5px 10px rgb(225, 225, 225, 0.2)",
        }}
      >
        <RulesModal
          loading={loading}
          setLoading={setLoading}
          refetch={props.refetch}
          integrations={props.integrations}
          closeModal={() => props.setModalOpen(false)}
        />
      </div>
    </section>
  );
}

function RulesModal(props: {
  integrations: TenantIntegrationData[];
  setLoading: (loading: boolean) => void;
  loading: boolean;
  refetch: () => void;
  closeModal: () => void,
}) {
  const toast = useToast();

  const [modalOpen, setModalOpen] = useState(false);
  const [rules, setRules] = useState<RuleType[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const { integrations } = props;
  useEffect(() => {
    if (!props || !integrations) return;

    // find the rule integration
    const ruleIntegrationsItem = integrations.find(
      (integration) => integration.id === "rules"
    );

    const rulesIntegration =
      ruleIntegrationsItem && ruleIntegrationsItem.value
        ? ruleIntegrationsItem.value
        : [];

    setRules(rulesIntegration);

  }, [integrations]);

  const addNewRule = () => {
    const isValidRules = validateRules(rules);

    if (isValidRules) {
      setRules([
        ...rules,
        {
          name: "",
          integration: "",
          rule: defaultRule,
          id: Math.round(Math.random() * Math.random() * 10000),
        },
      ]);
    } else {
      toast({
        title: `Please ensure all previous rules are fixed`,
        status: "error",
      });
    }
  };

  // Go through all the rules and make sure that they are all present before adding a new rule or saving a rule
  const validateRules = (rulesParameter) => {
    const ruleIsValid = rulesParameter.every((r) => r.name && r.rule);
    return ruleIsValid;
  };

  // Update the parameters of a rule
  const updateRule = (index, key, value) => {
    const rulesClone = [...rules];

    const updatedRule = rulesClone[index];
    updatedRule[key] = value;

    rulesClone[index] = updatedRule;
    setRules(rulesClone);
  };

  // delete an entry from the list
  const deleteEntry = (indexToDelete) => {
    const rulesClone = [...rules].filter((_, index) => index !== indexToDelete);

    setRules(rulesClone);
  };

  const integrationsData = Object.values(props.integrations).map(
    ({ id: IntegrationId, name }) => ({
      name,
      IntegrationId,
    })
  );
  const integrationOptions = integrationsData
    ? integrationsData.map(({ IntegrationId, name }) => ({
        label: name,
        value: IntegrationId as string,
      }))
    : [];

  const saveRule = async () => {
    if (props.loading) return;
    props.setLoading(true);

    try {
      const isValidRules = validateRules(rules);
      if (isValidRules) {
        await updateRulesProps(toast, rules);
        await props.refetch();
        props.closeModal();
        // implement loader
      } else {
        toast({
          title: `Please ensure all previous rules are fixed`,
          status: "error",
        });
      }
    } catch (err: any) {
      toast({
        title: err.message,
        status: "error",
      });
    } finally {
      props.setLoading(false);
    }
  };

  const handleSelectChange = (e, index) => {
    updateRule(index, "integration", e.target.value);
  };

  return (
    <div>
      <section>
        <Box h="420px" p="5">
          {/* set a heading */}
          <Heading textAlign="center" size="md">
            Rules
          </Heading>
          {/* set a heading */}

          {/* add body */}
          <Flex mt="9" direction="column" gap="5">
            {/* loop through rules */}
            {rules.length ? (
              rules.map((rule, index) => (
                <Flex gap="20px" w="100%" justify="space-between" key={rule.id}>
                  <Flex gap="10px">
                    <Box w="270px">
                      <Select
                        value={rule.integration}
                        key={rule.id}
                        onChange={(e) => handleSelectChange(e, index)}
                        placeholder="Integration Type"
                      >
                        {integrationOptions.map((j) => (
                          <option key={j.value} value={j.value}>
                            {j.label}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    <Input
                      onChange={(e) => {
                        updateRule(index, "name", e.target.value);
                      }}
                      value={rule.name}
                      w="270px"
                      placeholder="Rule Name"
                      borderWidth="1px"
                    />
                  </Flex>

                  <Flex gap="10px">
                    <IconButton
                      colorScheme="blue"
                      aria-label="Search"
                      w="50px"
                      icon={<SettingsIcon />}
                      onClick={() => {
                        setModalOpen(true);
                        setActiveIndex(index);
                      }}
                    />
                    <IconButton
                      colorScheme="red"
                      aria-label="Search"
                      w="50px"
                      icon={<DeleteIcon />}
                      onClick={() => {
                        deleteEntry(index);
                      }}
                    />
                  </Flex>
                </Flex>
              ))
            ) : (
              <Flex w="100%" justify="space-between">
                <Text textAlign="center">No rules Present, Add some.</Text>
              </Flex>
            )}
            {/* loop through rules */}
          </Flex>
          {/* add body */}
        </Box>

        <Flex mt="5px" p="5" gap={3} justify="flex-end">
          <Button colorScheme="blue" onClick={addNewRule}>
            Add Rule
          </Button>
          <Button colorScheme="green" onClick={saveRule}>
            {props.loading ? <Spinner /> : <>Save Rules </>}
          </Button>
        </Flex>
      </section>
      <CodeEditorModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        json={rules[activeIndex]?.rule || ""}
        setJSON={(jsonString) => updateRule(activeIndex, "rule", jsonString)}
      />
    </div>
  );
}
