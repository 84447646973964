import { Box, Button, Heading, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { useMojoFetch } from "api/useMojoFetch";
import { useEffect, useState } from "react";

interface Credential {
    credentials: {
        clientId: string;
        clientSecret: string;
    }
}

export const Credentials = () => {
    const [credentialsList, setCredentialsList] = useState([])
    const [newCredentials, setNewCredentials] = useState<Credential | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [idToDelete, setIdToDelete] = useState(null)

    const { data: credentialsData, refetch: refreshCredentials } = useMojoFetch('/api/v1/ClientCredentials', 'get')
    const { data: generatedCredentials, run: createCredentials } = useMojoFetch('/api/v1/ClientCredentials/create', 'post', null, true)
    const { run: deleteCredentials } = useMojoFetch(`/api/v1/ClientCredentials/delete/${idToDelete}`, 'delete',  null, true)

    const handleCreateCredentials = async () => {
        await createCredentials()
        await refreshCredentials()
        setNewCredentials(prev => generatedCredentials)
        setIsModalOpen(true)
    }

    const handleDelete = async (id) => {
        // TO-DO: Add an ask before deleting 
        setIdToDelete(id)
        if (id) {
            deleteCredentials()
            setCredentialsList(prevList => prevList.filter((credential: any) => credential?.clientId !== id));
        }
    }

    useEffect(() => {
        setCredentialsList(credentialsData)
    }, [credentialsData])
    
    return (
        <Box>
            <Heading> Mojo Credentials </Heading>
            <Box pt={50}>
                <Button onClick={handleCreateCredentials}> Generate New </Button>
            </Box>

            <Box pt={20} maxH={900} overflow={'scroll'} >
                <Table variant='simple' >
                    <TableCaption>Credentials used for other services</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Client ID</Th>
                            <Th>Client Secret</Th>
                            <Th>Scopes</Th>
                            <Th>Description</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {credentialsList && credentialsList?.map((c: any, idx: Number) => {
                            return <Tr key={c?.clientId + idx}>
                                <Td>{c?.clientId}</Td>
                                <Td>{c?.clientSecret ? `${c.clientSecret.slice(0, 4)}******` : ''}</Td>
                                <Td>{c?.scopes}</Td>
                                <Td>{c?.description}</Td>
                                <Td>
                                    <Button variant={"solid"} bg={"red.400"} onClick={() => handleDelete(c?.clientId)}>Delete</Button>
                                </Td>
                            </Tr>
                        })}
                    </Tbody>

                </Table>
            </Box>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay />
                <ModalContent maxW={'800'} bg={'white'} p={10}>
                    <ModalHeader>Credentials</ModalHeader>
                    <Text> You will only be able to see these once </Text>
                    <ModalCloseButton />
                    <ModalBody >
                        {Object?.keys(newCredentials || {}).length > 0 &&
                            <Box key={newCredentials?.credentials?.clientId + 'new'}>
                                <p>Client ID: {newCredentials?.credentials?.clientId}</p>
                                <p>Client Secret: {newCredentials?.credentials?.clientSecret}</p>
                                <hr />
                            </Box>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}
